import React from 'react'
import { useTranslate } from 'react-polyglot'
import {
  BusinessNavigationItemName,
  NavigationItemName,
} from 'src/types'


import PageContainer from "../../../../components/PageContainer/PageContainer";
import { Box, Flex, Image } from "@chatterbug/aaron";
import Logo1 from './assets/logo-1.png'
import Logo2 from './assets/logo-2.png'
import Logo3 from './assets/logo-3.png'
import Logo4 from './assets/logo-4.png'
import background from "./assets/background.svg"
import FullWidthBackground from "../../../../components/FullWidthBackground/FullWidthBackground";

export type Props = {
  sectionName?: NavigationItemName | BusinessNavigationItemName
}

const BusinessClients: React.FC<Props> = ({ sectionName }) => {
  const t = useTranslate()

  return (
    <FullWidthBackground image={background} width={1439} height={741}>
      <Box mt={-16} pt={16}>
        <Box
          pt="6.62rem"
          pb="6.81rem"
          sx={{ background: "radial-gradient(#8FC0F8, #BADAFF)" }}
        >
          <PageContainer>
            <Box fontSize="1.75rem" lineHeight="1.2" textAlign="center" fontWeight="700" mb="3.5rem" color="white">
              {t('Empowering multilingual teams at')}
            </Box>
          </PageContainer>
          <Flex
            overflowX={{ _: 'hidden', tabletWide: 'auto' }}
            sx={{ justifyContent: 'center' }}
          >
            <Box sx={{ minWidth: '163px', maxWidth: '266px' }}>
              <Image src={Logo1}/>
            </Box>
            <Box sx={{ minWidth: '163px', maxWidth: '266px' }}>
              <Image src={Logo2}/>
            </Box>
            <Box sx={{ minWidth: '163px', maxWidth: '266px' }}>
              <Image src={Logo3}/>
            </Box>
            <Box sx={{ minWidth: '163px', maxWidth: '266px' }}>
              <Image src={Logo4}/>
            </Box>
          </Flex>
        </Box>
      </Box>
    </FullWidthBackground>
  )
}

export default BusinessClients
