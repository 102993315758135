import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex, Image } from '@chatterbug/aaron'

import { LanguageCode, LanguageDropDownItem, PageContext, SiteLocale } from 'src/types'
import SiteHero from 'src/sections/SiteHero/SiteHero'
import BusinessHeader from "../BusinessHeader/BusinessHeader";
import HeroDesktopEnImage from "./assets/hero-desktop-en.png";
import HeroDesktopDeImage from "./assets/hero-desktop-de.png";
import HeroDesktopEsImage from "./assets/hero-desktop-es.png";
import Button, { BUTTON_VARIANTS } from "../../../../components/Button/Button";
import { useLocale } from "../../../../lib/I18nContext";
import { getLocalizedPath } from "../../../../config/i18n";

export type Props = {
  pageContext: PageContext
  selectedL2Code: LanguageCode | null
  onSelectedL2Change: (item: LanguageDropDownItem | null) => void
}

export const BusinessActions = ({ header }: { header?: boolean }) => {
  const t = useTranslate()
  const locale = useLocale()

  const showChat = () => window.Intercom('show')

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        gridGap="1rem"
        mx="auto"
      >
        <Box flex="1 1 0" maxWidth="210px">
          <Button
            variant={BUTTON_VARIANTS.whiteWithOutline}
            width={{
              _: '100%',
            }}
            height={{ _: '44px' }}
            label={t('get in touch')}
            href={null}
            onClick={showChat}
            className={`get-in-touch-${header ? "top" : "bottom"}`}
          />
        </Box>
        <Box color="black19" fontSize="0.875rem" lineHeight="1.5625rem">
          {t('or')}
        </Box>
        <Box flex="1 1 0" maxWidth="210px">
          <Button
            variant={BUTTON_VARIANTS.primary}
            width={{
              _: '100%',
            }}
            sx={{
              background: 'linear-gradient(336deg, #B592F7 0%, #61D3F5 100%), linear-gradient(337deg, #704AB7 0%, #3B71FB 100%)',
              color: 'white',
              border: "none",
            }}
            height={{ _: '44px' }}
            label={t('book a free demo')}
            href={getLocalizedPath(locale, "/business/demo")}
            className={`book-demo-${header ? "top" : "bottom"}`}
          />
        </Box>
      </Flex>
    </>
  )
}

const BusinessHero: React.FC<Props> = ({
  pageContext,
  selectedL2Code,
  onSelectedL2Change,
}) => {
  const t = useTranslate()
  const locale = useLocale()

  const HeroImage = React.useMemo(() => {
    if (locale === SiteLocale.en) {
      return HeroDesktopEnImage
    }
    if (locale === SiteLocale.de) {
      return HeroDesktopDeImage
    }
    if (locale === SiteLocale.es) {
      return HeroDesktopEsImage
    }
    return undefined
  }, [locale])

  return (
    <SiteHero
      headline={t(
        'Give your <strong>employees</strong> the <em>fluency</em> to <strong>succeed</strong>'
      )}
      backgroundStyles={{
        sx: {
          position: 'absolute',
          left: '50%',
          right: '0',
          top: '0',
          transform: 'translate(-50%)',
        }
      }}
      sellingPoints={[
        t(
          '<strong>45 minute</strong> 1:1 classes with trained tutors'
        ),
        t(
          'Simple, consolidated billing'
        ),
        t(
          'User management tools and progress monitoring'
        ),
        t(
          'Flexible pricing with lessons from €13'
        ),
      ]}
      headerSection={
        <BusinessHeader
          l2Code={selectedL2Code}
          canonicalPath={pageContext.canonicalPath}
        />
      }
      headerStyles={{
        fontSize: {
          _: '2rem',
          mobileWide: '2.5rem',
          tablet: '3.25rem',
          desktop: '3.75rem',
        },
        lineHeight: '1.2'
      }}
      infoBottomSection={
        <Box
          mb={{ _: '5x', mobile: 48 }}
          mt={{ _: '3x', mobile: '5x', tablet: 48 }}
          minWidth={{ _: '13.125rem' }}
          width="100%"
        >
          <BusinessActions header/>
        </Box>
      }
      previewSection={
        <Box
          maxWidth={{ _: 350, tablet: '100%' }}
          mx="auto"
          mt={{ _: '2.3rem', mobile: '4x', tablet: 0 }}
        >
          <Image
            src={HeroImage}
            width="100%"
            height="100%"
            loading="lazy"
            alt=""
            sx={{
              position: 'relative',
              bottom: -1,
            }}
          />
        </Box>
      }
    />
  )
}

export default BusinessHero
