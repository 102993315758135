import React from 'react'

export type Props = {
  accentColor: string
}

const Icon: React.FC<Props> = ({ accentColor }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 13.5714L8.49775 18.3348C8.88724 18.7061 9.50066 18.7025 9.88587 18.3269L21.5 7"
        stroke={accentColor}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Icon
