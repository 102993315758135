import React from 'react'
import { useTranslate } from 'react-polyglot'
import {
  BusinessNavigationItemName,
  NavigationItemName,
} from 'src/types'


import PageContainer from "../../../../components/PageContainer/PageContainer";
import { Box, Flex, Image } from "@chatterbug/aaron";
import LiteImage from './assets/lite.svg'
import CasualImage from './assets/casual.svg'
import SeriousImage from './assets/serious.svg'
import UltimateImage from './assets/ultimate.svg'
import { getLocalizedPath } from "../../../../config/i18n";
import { useLocale } from "../../../../lib/I18nContext";

export type Props = {
  sectionName?: NavigationItemName | BusinessNavigationItemName
}

type PlanCardProps = {
  title: string,
  id: string,
  price: number,
  image: string,
  text: string,
  popular?: boolean
}

const PlanCard: React.FC<PlanCardProps> = ({ popular, id, price, title, image, text }) => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <Box
      as="a"
      href={getLocalizedPath(locale, "/business/demo")}
      p="1.5rem"
      sx={{
        borderRadius: '1rem',
        position: 'relative',
        textDecoration: 'none',
        color: 'inherit',
        appearance: 'none',
        border: 'none',
        textAlign: 'left',
        cursor: 'pointer',
      }}
      backgroundColor="white"
      mt={{
        tabletWide: popular ? undefined : "41.6px"
      }}
      flex="1 1 0"
      className={`plan-${id}`}
    >
      {popular && <Box
        backgroundColor="#F4C856"
        py=".5rem"
        fontSize="1rem"
        lineHeight="1.6"
        textAlign="center"
        height="41.6px"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          borderRadius: '1rem 1rem 0 0',
        }}>{t('Our most popular plan!')}</Box>}
      <Flex
        sx={{ justifyContent: "space-between" }}
        pt={popular ? "2.31rem" : undefined}
      >
        <Box>
          <Box as="h5" fontSize="1.75rem" lineHeight="1.2">
            {title}
          </Box>
          <Box>
            <Box color="pink51" fontSize="3rem" lineHeight="1.2" fontWeight="700">
              {price}<Box as="sup" fontSize="1rem" sx={{ positon: 'relative', top: '-1.5rem' }}>€</Box>
            </Box>
            <Box color="gray46" fontSize=".75rem">
              {t('per month, per user')}
            </Box>
          </Box>
        </Box>
        <Box sx={{ alignSelf: "flex-end" }}>
          <Image src={image}/>
        </Box>
      </Flex>
      <Box
        as="hr"
        color="gray80"
        borderColor="gray80"
        backgroundColor="gray80"
        height="1px" my="1rem"/>
      <Box
        sx={{ whiteSpace: "pre-line", "& strong": { fontWeight: "800", fontSize: "1.25rem" } }}
        fontSize="1rem"
        lineHeight="1.6"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Box>
  )
}

const BusinessPlans: React.FC<Props> = ({ sectionName }) => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <Box>
      <PageContainer>
        <Box fontSize="1.75rem" lineHeight="1.2" textAlign="center" fontWeight="700">
          {t('Flexible Pricing')}
        </Box>
        <Box
          fontSize="1rem"
          mt="1.5rem"
          lineHeight={{ _: "1.2", tabletWide: '1.6' }}
          textAlign="center"
          sx={{
            whiteSpace: 'pre-line',
          }}
          dangerouslySetInnerHTML={{ __html: t('Stay <strong>flexible</strong> with per-learner plan asssignment. <strong>Cancel or change anytime.</strong>\nAdmin users are <strong>free</strong> of charge.') }}/>
        <Flex
          flexDirection={{
            _: "column",
            tabletWide: "row"
          }}
          gridGap={{
            _: "1rem",
            tabletWide: "1.56rem"
          }}
          mt="3.5rem"
        >
          <PlanCard
            title={t('Lite')}
            id="lite"
            price={23}
            image={LiteImage}
            text={t('<strong>1 Live Lesson</strong>\n€23 per extra Live Lesson \nIncl. Self Study activities')}
          />
          <PlanCard
            title={t('Casual')}
            id="casual"
            price={84}
            image={CasualImage}
            text={t('<strong>4 Live Lessons</strong>\n€21 per extra Live Lesson \nIncl. Self Study activities')}
          />
          <PlanCard
            title={t('Serious')}
            id="serious"
            price={155}
            image={SeriousImage}
            text={t('<strong>8 Live Lessons</strong>\n€20 per extra Live Lesson \nIncl. Self Study activities')}
            popular
          />
          <PlanCard
            title={t('Ultimate')}
            id="ultimate"
            price={445}
            image={UltimateImage}
            text={t('<strong>31 Live Lessons</strong>\n€15 per extra Live Lesson \nIncl. Self Study activities')}
          />
        </Flex>
        <Box mt="5rem" textAlign="center">
          <Box
            fontSize="1.125rem"
            fontWeight="700"
            sx={{
              position: 'relative',
              '&::before, &::after': {
                content: "''",
                position: 'absolute',
                top: '50%',
                height: '1px',
                width: 'calc(50% - 30px)',
                backgroundColor: 'rgba(0, 0, 0, 0.20)',
                borderRadius: 'inherit',
                transition: 'inherit',
              },
              '&::before': {
                left: 0
              },
              '&::after': {
                right: 0
              },
            }}>
            {t('or')}
          </Box>
          <Box fontSize="1.75rem" lineHeight="1.2" fontWeight="700" mt="1.5rem">
            {t('Looking to bulk-buy lessons for your large team?')}
          </Box>
          <Box
            as="a"
            href={getLocalizedPath(locale, "/business/demo")}
            color="#E61C54"
            fontSize="1rem"
            lineHeight="1.2"
            fontWeight="700"
            mt="1.5rem"
            sx={{
              borderBottom: '1.5px solid #E61C54',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              backgroundColor: 'transparent',
              display: 'inline-block',
              textDecoration: "none",
              cursor: 'pointer',
            }}
            pb=".38rem"
            className="plan-enterprise"
          >
            {t('Talk to us about enterprise pricing')}
          </Box>
        </Box>
      </PageContainer>
    </Box>
  )
}

export default BusinessPlans
