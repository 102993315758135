import React from 'react'
import { Flex, Box } from '@chatterbug/aaron'

import Icon from './assets/Icon'
import { usePageThemeContext } from 'src/lib/pageThemeContext'

export type Props = {
  label: string
  variant: 'light' | 'dark'
}

const SellingPoint: React.FC<Props> = ({ label, variant }) => {
  const pageTheme = usePageThemeContext()

  return (
    <Flex display="inline-flex" alignItems="flex-start">
      <Box width={25} height={25} mr="2x" flexShrink={0}>
        <Icon
          accentColor={
            pageTheme.sellingPointAccentColor || pageTheme.accentColor
          }
        />
      </Box>
      <Box
        as="p"
        variant="text.paragraph"
        color={variant === 'light' ? 'white100' : 'black19'}
        dangerouslySetInnerHTML={{
          __html: label,
        }}
      />
    </Flex>
  )
}

export default SellingPoint
