import React from 'react'
import { useTranslate } from 'react-polyglot'
import {
  BusinessNavigationItemName,
  NavigationItemName,
} from 'src/types'


import { Box, Flex } from "@chatterbug/aaron";
import Flag from "../../../../components/Flag/Flag";
import PageContainer from "../../../../components/PageContainer/PageContainer";

export type Props = {
  sectionName?: NavigationItemName | BusinessNavigationItemName
}

const BusinessLanguages: React.FC<Props> = ({ sectionName }) => {
  const t = useTranslate()

  return (
    <PageContainer css={{maxWidth: "958px"}}>
      <Box
        sx={{ position: "relative" }}
        height={{ _: "13.87rem", tabletWide: "10rem" }}
      >
        <Flex
          backgroundColor="white"
          px={{
            _: "1.5rem",
            tabletWide: "5.69rem"
          }}
          py={{
            _: "3rem",
            tabletWide: "1.88rem"
          }}
          position="absolute"
          bottom={{
            _: "-135px",
            tabletWide: "-56px"
          }}
          maxWidth="958px"
          borderRadius={{
            _: "1.25rem",
            mobileWide: "2.5rem"
          }}
          textAlign="center"
          width="100%"
          flexDirection={{
            _: "column",
            tabletWide: "row"
          }}
          gridGap={{
            tabletWide: "1.5rem"
          }}
          sx={{
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 2px 4px 0px rgba(0, 0, 0, 0.03), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 16px 0px rgba(0, 0, 0, 0.03)"
          }}
        >
          <Box mb={{
            _: ".75rem",
            tabletWide: "0"
          }}
               textAlign={{
                 _: "center",
                 tabletWide: "left"
               }}
          >
            <Box as="h2" variant="text.h3">
              {t('Choose from these languages')}
            </Box>
            <Box
              as="p"
              mt="1.63rem"
              fontSize="1rem"
              lineHeight="1.6"
              sx={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{ __html: t('<strong>English, German, French or Spanish</strong>\nLearners can study multiple languages simultaneously') }}/>
          </Box>
          <Box
            as="hr"
            height={{
              _: "1px",
              tabletWide: "auto"
            }}
            width={{
              _: "auto",
              tabletWide: "1px"
            }}
            color="gray80"
            borderColor="gray80"
            backgroundColor="gray80"
            my={{
              _: "1rem",
              tabletWide: "0"
            }}
            ml={{tabletWide: "auto"}}
          />
          <Flex
            sx={{ gap: '1.5rem', justifyContent: "center", flex: "0 0 auto", alignSelf: "center" }}
            mt={{ _: ".75rem", tabletWide: "0" }}

          >
            <Flag code="en" size="large"/>
            <Flag code="de" size="large"/>
            <Flag code="fr" size="large"/>
            <Flag code="es" size="large"/>
          </Flex>
        </Flex>
      </Box>
    </PageContainer>
  )
}

export default BusinessLanguages
