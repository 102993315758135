import React from 'react'
import { Flex, Grid } from '@chatterbug/aaron'

import SellingPoint, {
  Props as SellingPointProps,
} from './subcomponent/SellingPoint/SellingPoint'

export type Props = {
  items: string[]
  variant: SellingPointProps['variant']
}

const SellingPoints: React.FC<Props> = ({ items, variant }) => {
  return (
    <Grid
      gridTemplateColumns={{
        _: '1fr',
        tablet: `repeat(${items.length}, 1fr)`,
      }}
      gridGap={{
        _: '3x',
        tablet: 0,
      }}
      maxWidth={{ _: 'fit-content', tablet: '100%' }}
      mx="auto"
    >
      {items.map((item, index) => (
        <Flex
          key={index}
          mx={{
            _: 0,
            tablet: '2x',
            laptop: '5x',
          }}
          justifyContent="flex-start"
        >
          <SellingPoint label={item} variant={variant} />
        </Flex>
      ))}
    </Grid>
  )
}

export default SellingPoints
