import React from 'react'

export type Props = {
  accentColor: string
  secondaryColor: string
}

const Stars: React.FC<Props> = () => {
  return (
    <svg
      width="675"
      height="216"
      viewBox="0 0 675 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#B592F7"/>
          <stop offset="100%" stopColor="#9AE5FC"/>
        </linearGradient>
      </defs>
      <path
        d="M609.17 8.627C609.453 6.9688 611.532 6.37998 612.643 7.64339L616.445 11.9678C616.892 12.4763 617.566 12.7241 618.236 12.6263L623.933 11.795C625.598 11.5521 626.801 13.3477 625.942 14.7945L623.004 19.7466C622.659 20.3288 622.631 21.0463 622.931 21.6533L625.483 26.8152C626.228 28.3233 624.892 30.0218 623.251 29.6526L617.633 28.3887C616.973 28.2401 616.282 28.4357 615.797 28.9086L611.677 32.9302C610.473 34.1051 608.444 33.3593 608.288 31.6843L607.754 25.9511C607.692 25.277 607.292 24.6804 606.693 24.3657L601.594 21.6893C600.105 20.9073 600.188 18.7478 601.732 18.0818L607.02 15.8024C607.642 15.5344 608.086 14.9701 608.2 14.3027L609.17 8.627Z"
        fill="url(#Gradient1)"
      />
      <path
        d="M131.23 40.5233C131.64 39.099 133.403 38.5999 134.499 39.5977L137.429 42.2658C137.815 42.6168 138.322 42.8034 138.843 42.7858L142.804 42.6516C144.285 42.6015 145.304 44.1235 144.694 45.4741L143.062 49.0855C142.847 49.5606 142.827 50.1009 143.005 50.5911L144.356 54.3165C144.862 55.7097 143.729 57.1495 142.256 56.9864L138.317 56.5503C137.799 56.4929 137.279 56.6402 136.867 56.9608L133.742 59.3974C132.573 60.3086 130.854 59.6764 130.554 58.225L129.751 54.3442C129.646 53.8335 129.345 53.3843 128.913 53.0922L125.63 50.8727C124.402 50.0426 124.472 48.2122 125.759 47.4782L129.202 45.5158C129.655 45.2576 129.99 44.8327 130.134 44.3316L131.23 40.5233Z"
        fill="#CCE8FF"
      />
      <path
        d="M48.2152 174.501C48.6252 173.077 50.3877 172.578 51.4836 173.576L57.8199 179.345C58.2055 179.696 58.713 179.883 59.2341 179.865L67.7985 179.575C69.2797 179.525 70.2991 181.047 69.6888 182.397L66.1599 190.206C65.9452 190.682 65.9246 191.222 66.1024 191.712L69.0248 199.768C69.5302 201.161 68.3976 202.601 66.9246 202.438L58.4073 201.495C57.889 201.437 57.3688 201.585 56.9576 201.905L50.1993 207.174C49.0304 208.085 47.3111 207.453 47.011 206.002L45.2759 197.61C45.1703 197.099 44.8695 196.65 44.4375 196.358L37.3382 191.558C36.1104 190.728 36.1804 188.898 37.468 188.164L44.9129 183.92C45.3659 183.662 45.7002 183.237 45.8445 182.736L48.2152 174.501Z"
        fill="url(#Gradient1)"
      />
      <path
        d="M645.035 156.412C646.295 155.298 648.284 156.143 648.358 157.824L648.493 160.93C648.523 161.607 648.893 162.222 649.476 162.566L652.155 164.145C653.604 164.999 653.415 167.152 651.839 167.741L648.926 168.83C648.292 169.067 647.821 169.609 647.674 170.27L647 173.306C646.636 174.948 644.53 175.434 643.483 174.117L641.547 171.684C641.125 171.154 640.465 170.873 639.791 170.938L636.695 171.235C635.021 171.395 633.908 169.543 634.837 168.14L636.553 165.547C636.927 164.982 636.989 164.267 636.72 163.646L635.481 160.794C634.81 159.251 636.228 157.62 637.849 158.07L640.846 158.901C641.498 159.082 642.198 158.921 642.705 158.472L645.035 156.412Z"
        fill="#CCE8FF"
      />
      <circle cx="75" cy="89" r="2" fill="url(#Gradient1)"/>
      <circle cx="4.5" cy="84.5" r="4.5" fill="#CCE8FF"/>
      <circle cx="671.5" cy="103.5" r="3.5" fill="url(#Gradient1)"/>
    </svg>
  )
}

export default Stars
