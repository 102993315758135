import React from 'react'
import { useTranslate } from 'react-polyglot'
import {
  BusinessNavigationItemName,
  NavigationItemName,
} from 'src/types'


import PageContainer from "../../../../components/PageContainer/PageContainer";
import { Flex, Image } from "@chatterbug/aaron";
import CompareSvg from './assets/compare.svg'
import NoSvg from './assets/no.svg'
import YesSvg from './assets/yes.svg'
import CompleteSvg from './assets/complete.svg'
import { Box } from "rebass";
import { useMediaQueryOnClient } from "../../../../lib/use-helpers";
import { usePageThemeContext } from "../../../../lib/pageThemeContext";

export type Props = {
  sectionName?: NavigationItemName | BusinessNavigationItemName
}

enum IconType {
  No,
  Yes,
  Complete
}

type ItemProps = ({ type: "text", content: string } | { type: "icon", content: IconType })

type ItemsProps = {
  headerColumn: ItemProps[],
  itemColumns: ItemProps[][]
}

const Item: React.FC<ItemProps & { header?: boolean }> = ({ type, content, header }) => {
  const Icon = React.useMemo(() => {
    if (type !== "icon") {
      return null
    }
    if (content === IconType.No) {
      return NoSvg
    }
    if (content === IconType.Yes) {
      return YesSvg
    }
    if (content === IconType.Complete) {
      return CompleteSvg
    }
    return null
  }, [type, content])

  return (
    <Flex
      flex={header ? "1 1 30%" : "1 1 0"}
      as="p"
      variant="text.paragraph"
      mx={{ tabletWide: header ? "0" : "2rem" }}
      sx={{
        justifyContent: header ? "flex-start" : "center",
        alignItems: "center",
        textAlign: header ? "left" : "center",
      }}
      color={header ? undefined : "gray60"}
    >
      {type === "text" && content}
      {Icon && <Image src={Icon}/>}
    </Flex>
  )
}

const BusinessPriceBlock: React.FC<{ items: ItemsProps }> = ({ items }) => {
  const pageTheme = usePageThemeContext()
  const t = useTranslate()
  const headers = items.itemColumns.map(i => i[0])
  const itemsWithoutHeaders = items.itemColumns[0].map((x, i) => items.itemColumns.map(x => x[i]))
  return (
    <Flex sx={{ flexDirection: "column", gap: ".19rem" }}>
      <Flex sx={{
        borderRadius: '1rem 1rem 0 0',
      }}
            backgroundColor="white"
            p="1.25rem"
            alignItems="center"
      >
        <Flex
          flex="1 1 30%"
          sx={{
            alignItems: 'baseline',
          }}
          flexDirection={{
            _: "column",
            tabletWide: "row"
          }}
          gridGap={{
            tabletWide: "1.25rem"
          }}
          alignItems="center"
          justifyContent="flex-start"
          fontSize={{
            _: '1rem',
            tabletWide: "1.75rem"
          }}
        >
          <Image src={CompareSvg}/>
          <Box
            as="span"
            fontWeight="700"
            dangerouslySetInnerHTML={{ __html: t('Compare chatterbug <em>for business</em>') }}
            sx={{
              "& em": {
                backgroundImage: pageTheme.accentGradient,
                backgroundClip: 'text',
                "-webkitBackgroundClip": 'text',
                color: 'transparent',
                fontStyle: 'normal',
              }
            }}
            maxWidth="18rem"
          />
        </Flex>
        {headers.map(h => (
          <Box
            flex="1 1 0"
            variant="text.paragraph"
            dangerouslySetInnerHTML={{ __html: h.content as string }}
            mx={{
              tabletWide: "2rem"
            }}
            sx={{
              textAlign: "center",
              "& em": {
                backgroundImage: pageTheme.accentGradient,
                backgroundClip: 'text',
                "-webkitBackgroundClip": 'text',
                color: 'transparent',
                fontStyle: 'normal',
              }
            }}
          />
        ))}
      </Flex>
      {itemsWithoutHeaders.slice(1).map((item, index) => (
        <Flex
          p="1.25rem"
          backgroundColor="white"
          borderRadius={index === itemsWithoutHeaders.length - 2 ? '0 0 1rem 1rem' : undefined}
        >
          <Item {...items.headerColumn[index]} header/>
          {item.map((i, index) => (<Item {...i}/>))}
        </Flex>
      ))}
    </Flex>
  )
}

const BusinessPrices: React.FC<Props> = ({ sectionName }) => {
  const t = useTranslate()
  const isDesktop = useMediaQueryOnClient('only screen and (max-width: 1024px)')

  const headerColumn: ItemProps[] = [
    {
      type: 'text',
      content: t('Focused 1:1 Lessons'),
    },
    {
      type: 'text',
      content: t('Easy 24/7 Scheduling'),
    },
    {
      type: 'text',
      content: t('Interactive Online Classroom'),
    },
    {
      type: 'text',
      content: t('Individual Levelling For Each Learner'),
    },
    {
      type: 'text',
      content: t('Automated progress tracking and certification'),
    },
    {
      type: 'text',
      content: t('Flexible Per-Employee Billing'),
    },
  ]
  const firstColumn: ItemProps[] = [
    {
      type: 'text',
      content: t('Other online platforms')
    },
    {
      type: 'icon',
      content: IconType.No,
    },
    {
      type: 'icon',
      content: IconType.Yes,
    },
    {
      type: 'text',
      content: t('Zoom or similar'),
    },
    {
      type: 'icon',
      content: IconType.No,
    },
    {
      type: 'icon',
      content: IconType.No,
    },
    {
      type: 'icon',
      content: IconType.Yes,
    },
  ]
  const secondColumn: ItemProps[] = [
    {
      type: 'text',
      content: t('In-person group training')
    },
    {
      type: 'icon',
      content: IconType.No,
    },
    {
      type: 'icon',
      content: IconType.No,
    },
    {
      type: 'icon',
      content: IconType.No,
    },
    {
      type: 'icon',
      content: IconType.No,
    },
    {
      type: 'icon',
      content: IconType.No,
    },
    {
      type: 'icon',
      content: IconType.No,
    },
  ]
  const thirdColumn: ItemProps[] = [
    {
      type: 'text',
      content: t('<strong>chatterbug</strong> <em>for business</em>')
    },
    {
      type: 'icon',
      content: IconType.Complete,
    },
    {
      type: 'icon',
      content: IconType.Complete,
    },
    {
      type: 'icon',
      content: IconType.Complete,
    },
    {
      type: 'icon',
      content: IconType.Complete,
    },
    {
      type: 'icon',
      content: IconType.Complete,
    },
    {
      type: 'icon',
      content: IconType.Complete,
    },
  ]
  let items: ItemsProps[]
  if (isDesktop) {
    items = [
      {
        headerColumn: headerColumn,
        itemColumns: [firstColumn]
      },
      {
        headerColumn: headerColumn,
        itemColumns: [secondColumn]
      },
      {
        headerColumn: headerColumn,
        itemColumns: [thirdColumn]
      },
    ]
  } else {
    items = [
      {
        headerColumn: headerColumn,
        itemColumns: [firstColumn, secondColumn, thirdColumn]
      }
    ]
  }

  return (
    <Box sx={{
      background: "linear-gradient(#CADCFF, #DEE8FB)"
    }}>
      <PageContainer css={{
        paddingTop: "10rem",
        paddingBottom: '10rem',
        gap: '1.25rem',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: "958px"
      }}>
        {items.map(i => <BusinessPriceBlock items={i}/>)}
      </PageContainer>
    </Box>
  )
}

export default BusinessPrices
