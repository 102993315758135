import React from 'react'
import { withTheme } from 'styled-components'
import { Box, Grid, Flex } from '@chatterbug/aaron'

import { PageAccentVariant } from 'src/types'
import PageContainer from 'src/components/PageContainer/PageContainer'
import GradientBackground, {
  makeCentered,
} from 'src/components/GradientBackground/GradientBackground'
import SellingPoints, {
  Props as SellingPointsProps,
} from 'src/components/SellingPoints/SellingPoints'
import { usePageThemeContext } from 'src/lib/pageThemeContext'

export type Props = {
  headline: string
  headerSection?: JSX.Element
  infoTopSection?: JSX.Element
  infoBottomSection?: JSX.Element
  infoBottomPosition?: 'alwaysBottom' | 'mobileBottom'
  previewSection?: JSX.Element
  ratingSection?: JSX.Element
  sellingPoints: SellingPointsProps['items']
  headerStyles?: any,
  backgroundStyles?: any,
  theme?: {
    breakpoints: {
      tablet: string
      tabletWide: string
      laptop: string
    }
  }
}

const SiteHero: React.FC<Props> = ({
                                     headline,
                                     headerSection,
                                     infoTopSection,
                                     infoBottomSection,
                                     infoBottomPosition = 'alwaysBottom',
                                     previewSection,
                                     sellingPoints,
                                     ratingSection,
                                     theme,
                                     headerStyles,
                                     backgroundStyles,
                                   }) => {
  const pageTheme = usePageThemeContext()

  const emColor = React.useMemo(() => {
    if (!pageTheme.accentGradient) {
      return {
        color: pageTheme.accentColor
      }
    }
    return {
      backgroundImage: pageTheme.accentGradient,
      backgroundClip: 'text',
      "-webkitBackgroundClip": 'text',
      color: 'transparent',
    }
  }, [pageTheme.accentColor, pageTheme.accentGradient])

  return (
    <Box
      sx={{
        overflow: 'hidden',
      }}
    >
      <PageContainer>
        <Box position="relative">
          {/* background gradient */}
          <GradientBackground
            variant={pageTheme.accentVariant || PageAccentVariant.pink}
            sx={{
              position: 'absolute',
              [`@media screen and (max-width: ${
                parseInt(String(theme?.breakpoints.tablet)) - 1
              }px)`]: {
                top: -200,
                ...makeCentered(),
              },
              [`@media screen and (min-width: ${theme?.breakpoints.tablet})`]: {
                top: -800,
                right: -1100,
              },
              [`@media screen and (min-width: ${theme?.breakpoints.tabletWide})`]: {
                top: -800,
                right: -900,
              },
            }}
            {...backgroundStyles}
          />
          {headerSection}
          <Grid
            position="relative"
            columns={{ _: '1fr', tablet: '1fr 1fr' }}
            gridTemplateAreas={{
              _: `
                "infoTop"
                "preview"
                "infoBottom"
            `,
              tablet:
                infoBottomPosition === 'alwaysBottom'
                  ? `
                "infoTop preview"
                "infoBottom infoBottom"
            `
                  : `
                "infoTop preview"
                "infoBottom preview"
            `,
              tabletWide: `
                "infoTop preview"
                "infoBottom preview"
            `,
            }}
            gridGap={0}
            pt={{
              _: 40,
              tablet: 64,
              tabletWide: 24,
            }}
            mb={{_: "1rem", tabletWide: "2rem"}}
          >
            <Flex
              flexDirection="column"
              justifyContent={{
                tablet: 'center',
                tabletWide: 'flex-end',
              }}
              alignItems={{
                _: 'center',
                tablet: 'flex-start',
              }}
              textAlign={{
                _: 'center',
                tablet: 'left',
              }}
              pr={{ _: 0, tablet: '3x' }}
              sx={{ gridArea: 'infoTop' }}
            >
              <Box
                as="h1"
                fontSize={{
                  _: 36,
                  tablet: 52,
                  tabletWide: 60,
                }}
                sx={{
                  fontWeight: 'regular',
                  strong: {
                    fontWeight: 'semiBold',
                  },
                  em: {
                    ...emColor,
                    fontWeight: 'semiBold',
                    fontStyle: 'normal',
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: headline,
                }}
                {...headerStyles}
              />
              {infoTopSection}
            </Flex>
            <Box sx={{ gridArea: 'preview' }}>{previewSection}</Box>
            <Flex
              flexDirection="column"
              alignItems={{
                _: 'center',
                tablet:
                  infoBottomPosition === 'alwaysBottom'
                    ? 'center'
                    : 'flex-start',
                tabletWide: 'flex-start',
              }}
              textAlign={{
                _: 'center',
                tablet:
                  infoBottomPosition === 'alwaysBottom' ? 'center' : 'left',
                tabletWide: 'left',
              }}
              sx={{
                gridArea: 'infoBottom',
              }}
            >
              {infoBottomSection}
            </Flex>
          </Grid>
        </Box>
        <Box
          sx={{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: pageTheme.accentColor,
            opacity: 0.3,
          }}
        />
        <Grid
          columns={{ _: '1fr', tablet: ratingSection ? '20% 1fr' : '1fr' }}
          gridTemplateAreas={{
            _: `
                "points"
                "rating"
            `,
            tablet: `
                "rating points"
            `,
          }}
          gridGap={{ _: '4x', tablet: '3x' }}
          mt="4x"
          mb={96}
        >
          {ratingSection && (
            <Box
              textAlign={{ _: 'center', tablet: 'left' }}
              sx={{ gridArea: 'rating' }}
            >
              {ratingSection}
            </Box>
          )}
          <Box sx={{ gridArea: 'points' }}>
            <SellingPoints variant="dark" items={sellingPoints}/>
          </Box>
        </Grid>
      </PageContainer>
    </Box>
  )
}

export default withTheme(SiteHero)
