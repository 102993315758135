import React from 'react'
import { useTranslate } from 'react-polyglot'
import PageContainer from 'src/components/PageContainer/PageContainer'

import Slide1 from './assets/slide1.svg'
import Slide2 from './assets/slide2.svg'
import Slide3 from './assets/slide3.svg'

import { Box, Flex, Image } from "@chatterbug/aaron";

type FeatureProps = {
  image: string
  title: string
  text: string
}

const BusinessFeature: React.FC<FeatureProps> = ({ image, text, title }) => {
  return (
    <Box>
      <Image src={image} width="100%" loading="lazy" height="164px"/>
      <Box mt="1.5rem">
        <Box as="h2" fontSize={{
          _: "1.5rem",
          mobileWide: "1.75rem",
        }} lineHeight="1.2" textAlign="center">
          {title}
        </Box>
        <Box as="p" fontSize="1rem" lineHeight="1.6" textAlign="center" mt=".75rem"
             dangerouslySetInnerHTML={{
               __html: text,
             }}/>
      </Box>
    </Box>
  )
}
const BusinessFeatures: React.FC = () => {
  const t = useTranslate()

  const items = [
    {
      image: Slide1,
      title: t('Interactive lessons'),
      text: t('Your team doesn’t want another Zoom meeting! Chatterbug’s <strong>fully integrated online-classroom</strong> is fun, focused and effective')
    },
    {
      image: Slide2,
      title: t('For every level'),
      text: t('Our CEFR-alligned courses from <strong>A1 - C2</strong> adapt automatically to each learner’s individual progress')
    },
    {
      image: Slide3,
      title: t('Easy to administer'),
      text: t('Easily add and remove learners from your account with free <strong>user management features</strong> and <strong>automated billing</strong>')
    },
  ]

  return (
    <PageContainer css={{marginTop: "60px"}}>
      <Box as="h2" fontSize={{
        _: "1.5rem",
        mobileWide: "1.75rem",
      }} fontWeight="700" textAlign="center">
        {t('Online language learning for modern teams')}
      </Box>
      <Box
        sx={{ textAlign: 'center' }}
        mt="1.5rem"
        fontSize={{
          _: "1rem",
          tablet: "1.25rem",
        }}
        lineHeight="1.6"
        textAlign="center"
      >
        {t('Whether you want to help your expats feel at home, or you have executives who need to get up to speed swiftly, Chatterbug is the best way for your employees to get conversational quickly and flexibly.')}
      </Box>

      <Flex
        flexDirection={{
          _: "column",
          tabletWide: "row"
        }}
        gridGap={{ _: '2.5rem', tabletWide: '1.25rem', desktop: '1.56rem' }}
        mt="3.5rem"
      >
        {items.map(i => <BusinessFeature {...i}/>)}
      </Flex>
    </PageContainer>
  )
}

export default BusinessFeatures
