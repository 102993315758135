import {PageContext} from "../../types";
import React from "react";
import {PageProps} from "gatsby";
import Layout from "src/components/Layout/Layout";
import Business from "src/sections/Business/Business";
import Head from "../../components/Head/Head";
import {useTranslate} from "react-polyglot";
import {useLocale} from "../../lib/I18nContext";

type DataProps = Record<string, never>

const BusinessPage: React.FC<PageProps<DataProps, PageContext>> = ({
                                                                     pageContext,
                                                                   }) => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head
        pageContext={pageContext}
        titleOverride={t('Chatterbug for Business - Online Language Learning for Modern Teams')}
        description={t('Boost your employees’ language skills with 1:1 online lessons and tailored courses. Easy to implement with user management tools and progress tracking.')}
        socialImage={`/assets/global/og-images/business/${locale}.jpg`}
      />
      <Business pageContext={pageContext}/>
    </Layout>
  )
}
export default BusinessPage