import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'

import { LanguageCode, LanguageDropDownItem, PageAccentVariant } from 'src/types'
import { BusinessActions } from "../BusinessHero/BusinessHero";
import Stars from "./assets/Stars";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import { usePageThemeContext } from "../../../../lib/pageThemeContext";

export type Props = {
  selectedL2Code: LanguageCode | null
  onSelectedL2Change: (item: LanguageDropDownItem | null) => void
}

const BusinessBottom: React.FC<Props> = ({
                                           selectedL2Code,
                                           onSelectedL2Change,
                                         }) => {
  const pageTheme = usePageThemeContext()
  const t = useTranslate()

  return (
    <Box
      py={170}
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        display={{
          _: 'none',
          tablet: 'block',
        }}
        sx={{
          position: 'absolute',
          top: 110,
          left: '50%',
          width: 675,
          height: 216,
          marginLeft: -675 / 2,
        }}
      >
        <Stars
          accentColor={pageTheme.accentColor}
          secondaryColor={
            pageTheme.accentVariant === PageAccentVariant.pink
              ? '#ffd2d2'
              : 'white'
          }
        />
      </Box>
      <PageContainer>
        <Flex
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          position="relative"
        >
          <Box
            maxWidth={{
              tablet: '60%',
              tabletWide: '45%',
            }}
            fontSize={{
              _: 36,
              tabletWide: 60,
            }}
            sx={{
              marginBottom: '4x',
              fontWeight: 'regular',
              strong: {
                fontWeight: 'semiBold',
              },
              em: {
                fontWeight: 'semiBold',
                fontStyle: 'normal',
                backgroundImage: pageTheme.accentGradient,
                backgroundClip: 'text',
                "-webkitBackgroundClip": 'text',
                color: 'transparent',
              },
            }}
            as="h2"
            dangerouslySetInnerHTML={{
              __html: t('Get your team <strong>speaking</strong> <em>today!</em>'),
            }}
          />
          <Flex width="100%">
            <BusinessActions/>
          </Flex>
        </Flex>
      </PageContainer>
    </Box>
  )
}

export default BusinessBottom
